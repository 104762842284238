import bbqWarLoseSound from '@/assets/audio/BBQ_WAR_Lose.mp3';
import bbqWarWinSound from '@/assets/audio/BBQ_WAR_win.mp3';
import beepSound from '@/assets/audio/beep.mp3';
import inactiveButtonSound from '@/assets/audio/Inactive_Button.mp3';
import upgradeFailSound from '@/assets/audio/Upgrade_Fail.mp3';
import upgradeSuccessSound from '@/assets/audio/Upgrade_Success.mp3';
import upgradeSound from '@/assets/audio/upgrade.mp3';
import { useSoundStore } from '@/store/soundStore';
import { useEffect, useRef } from 'react';

export const useSound = () => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<AudioBuffer | null>(null);
  const inactiveButtonBufferRef = useRef<AudioBuffer | null>(null);
  const winSoundBufferRef = useRef<AudioBuffer | null>(null);
  const loseSoundBufferRef = useRef<AudioBuffer | null>(null);
  const upgradeSuccessBufferRef = useRef<AudioBuffer | null>(null);
  const upgradeFailBufferRef = useRef<AudioBuffer | null>(null);
  const upgradeBufferRef = useRef<AudioBuffer | null>(null);
  const { isSoundOn } = useSoundStore();

  useEffect(() => {
    // 创建 AudioContext
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    audioContextRef.current = audioContext;

    // 加载并解码音频文件
    const loadAudio = async () => {
      try {
        const [beepResponse, inactiveResponse, winResponse, loseResponse, upgradeSuccessResponse, upgradeFailResponse, upgradeResponse] = await Promise.all([
          fetch(beepSound),
          fetch(inactiveButtonSound),
          fetch(bbqWarWinSound),
          fetch(bbqWarLoseSound),
          fetch(upgradeSuccessSound),
          fetch(upgradeFailSound),
          fetch(upgradeSound)
        ]);

        const [beepBuffer, inactiveBuffer, winBuffer, loseBuffer, upgradeSuccessBuffer, upgradeFailBuffer, upgradeBuffer] = await Promise.all([
          beepResponse.arrayBuffer(),
          inactiveResponse.arrayBuffer(),
          winResponse.arrayBuffer(),
          loseResponse.arrayBuffer(),
          upgradeSuccessResponse.arrayBuffer(),
          upgradeFailResponse.arrayBuffer(),
          upgradeResponse.arrayBuffer()
        ]);

        const [decodedBeep, decodedInactive, decodedWin, decodedLose, decodedUpgradeSuccess, decodedUpgradeFail, decodedUpgrade] = await Promise.all([
          audioContext.decodeAudioData(beepBuffer),
          audioContext.decodeAudioData(inactiveBuffer),
          audioContext.decodeAudioData(winBuffer),
          audioContext.decodeAudioData(loseBuffer),
          audioContext.decodeAudioData(upgradeSuccessBuffer),
          audioContext.decodeAudioData(upgradeFailBuffer),
          audioContext.decodeAudioData(upgradeBuffer)
        ]);

        audioBufferRef.current = decodedBeep;
        inactiveButtonBufferRef.current = decodedInactive;
        winSoundBufferRef.current = decodedWin;
        loseSoundBufferRef.current = decodedLose;
        upgradeSuccessBufferRef.current = decodedUpgradeSuccess;
        upgradeFailBufferRef.current = decodedUpgradeFail;
        upgradeBufferRef.current = decodedUpgrade;
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    };

    loadAudio();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const playSound = (buffer: AudioBuffer | null, volume: number = 0.5) => {
    if (!audioContextRef.current || !buffer || !isSoundOn) return;

    const source = audioContextRef.current.createBufferSource();
    source.buffer = buffer;

    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.value = volume;

    source.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);

    source.start(0);
  };

  const playBeep = () => {
    playSound(audioBufferRef.current, 0.5);
  };

  const playInactiveButton = () => {
    playSound(inactiveButtonBufferRef.current, 0.5);
  };

  const playWinSound = () => {
    playSound(winSoundBufferRef.current, 0.5);
  };

  const playLoseSound = () => {
    playSound(loseSoundBufferRef.current, 0.5);
  };

  const playUpgradeSuccess = () => {
    playSound(upgradeSuccessBufferRef.current, 0.5);
  };

  const playUpgradeFail = () => {
    playSound(upgradeFailBufferRef.current, 0.9);
  };

  const playUpgrade = () => {
    playSound(upgradeBufferRef.current, 0.5);
  };

  return { 
    playBeep, 
    playInactiveButton, 
    playWinSound, 
    playLoseSound,
    playUpgradeSuccess,
    playUpgradeFail,
    playUpgrade
  };
}; 