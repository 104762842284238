// 导入所需的库和类型
import getIconByName, { IconName } from "@/assets/icons";
import { useSound } from "@/hooks/useSound";
import { hapticFeedback } from "@telegram-apps/sdk-react"; // 导入触觉反馈功能
import { motion } from "framer-motion"; // 导入动画库
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"; // 导入路由链接组件
// 定义导航按钮的属性接口
interface NavButtonProps {
  to: string; // 路由路径
  icon: IconName; // 图标类型
  label: string; // 按钮标签
  isActive?: boolean; // 是否为活动状态
}

// 特殊导航按钮组件
export const SpecialNavButton = ({
  to,
  icon,
  label,
  isActive,
}: NavButtonProps) => {
  console.log({ icon });
  const { playBeep } = useSound();
  return (
    <Link
      to={to} // 路由链接
      className="relative  flex " // CSS类
      onClick={() => {
        playBeep();
        hapticFeedback.impactOccurred("medium"); // 点击时触发触觉反馈
      }}
    >
      <motion.div
        className="flex flex-col items-center justify-center w-[68px] h-[68px] bg-gradient-to-br from-[#FF339F] via-[#FF0099] to-[#CC33FF] rounded-full border border-[#FF339F]/40 relative overflow-hidden" // 更粉的渐变色
        whileHover={{
          scale: 1.05,
          boxShadow:
            "0 0 40px rgba(255,51,159,0.6), inset 0 0 20px rgba(255,0,153,0.5)", // 粉色霓虹发光
        }}
        whileTap={{ scale: 0.9 }}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-[#FF339F]/20 via-[#FF0099]/20 to-[#CC33FF]/20 backdrop-blur-sm"></div>
        <img
          src={
            isActive
              ? getIconByName("gameActive")
              : getIconByName("gameUnActive")
          }
          alt={label}
          className="w-full h-full text-xl text-white drop-shadow-[0_0_12px_rgba(255,51,159,0.8)] relative z-10"
        />
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#FF339F] via-[#FF0099] to-[#CC33FF] rounded-full opacity-40" // 动画背景
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.2, 0.15, 0.2],
          }}
          transition={{
            duration: 2.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      </motion.div>
    </Link>
  );
};

// 常规导航按钮组件
export const RegularNavButton = ({
  to,
  icon,
  label,
  isActive,
}: NavButtonProps) => {
  const { t } = useTranslation();
  const { playBeep } = useSound();
  return (
    <Link
      to={to}
      className="flex flex-col items-center justify-center gap-1 h-[40px] w-[72px]"
      onClick={() => {
        playBeep();
        hapticFeedback.impactOccurred("medium");
      }}
    >
      <motion.div
        whileTap={{ scale: 0.9 }}
        className={`relative h-[28px] w-[28px] p-1 flex items-center justify-center rounded-full transition-all duration-300 group ${
          isActive ? "bg-[#000]/80" : "text-gray-400 hover:text-gray-200"
        }`}
      >
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: isActive ? 1 : 0.5 }}
          transition={{ duration: 0.3 }}
          src={
            isActive
              ? getIconByName((icon + "Active") as IconName)
              : getIconByName(icon)
          }
          alt={label}
          className="text-xl relative z-10"
        />
      </motion.div>
      <div className="relative h-4 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: isActive ? 1 : 0 }}
          transition={{ duration: 0.2 }}
          className="absolute text-xs font-medium bg-gradient-to-r from-[#00FFFF] to-[#CE63FF] bg-clip-text text-transparent whitespace-nowrap text-center"
        >
          {t(`nav_${label}`)}
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isActive ? 0 : 1 }}
          transition={{ duration: 0.2 }}
          className="absolute text-xs font-medium text-gray-400 whitespace-nowrap text-center"
        >
          {t(`nav_${label}`)}
        </motion.div>
      </div>
    </Link>
  );
};
