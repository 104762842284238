import { authApi } from "@/api/auth";
import { useIsPc } from "@/hooks/useIsPc";
import { useUserStore } from "@/store/user";
import { SpinePlayer } from "@esotericsoftware/spine-player";
import {
  initDataAuthDate,
  retrieveLaunchParams,
} from "@telegram-apps/sdk-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PageTransition from "../components/PageTransition";



function Login() {
  const navigate = useNavigate();
  const setUserInfo = useUserStore((state) => state.setUserInfo);
  const { initData } = retrieveLaunchParams();
  const spineRef = useRef<SpinePlayer | null>(null);
  // @ts-expect-error 类型错误
  const { chatInstance, chatType, hash, isLink, queryId, startParam, user } =
    initData;

  useEffect(() => {
    authApi
      .login({
        auth_date: initDataAuthDate()?.toISOString() || "",
        chat_instance: Number(chatInstance) || 0,
        chat_type: chatType || "",
        hash: hash || "",
        isLink: isLink || false,
        query_id: queryId || "",
        start_param: startParam || "",
        user: {
          id: user!.id || 0,
          first_name: user!.firstName || "",
          last_name: user!.lastName || "",
          username: user!.username || "",
          language_code: user!.languageCode || "",
          is_premium: user!.isPremium || false,
          allows_write_to_pm: user!.allowsWriteToPm || false,
          ip_address: user!.ipAddress || "",
          photo_url: user!.photoUrl || "",
        },
      })
      .then(() => {
        // bettingApi.getCountrys();
        authApi.getServerTime();
        return authApi.getUserInfo();
      })
      .then((res) => {
        setTimeout(() => {
          setUserInfo(res.data);
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        
        // setUserInfo({
        //   globalUserId: "",
        //   gender: null,
        //   platformName: "",
        //   platformUserId: "",
        //   platformUsername: "",
        //   firstName: "",
        //   lastName: "",
        //   tgInviteUrl: "",
        //   webInviteUrl: "",
        //   isBlacklist: false,
        //   loginTime: "",
        // });
        // navigate("/home");
        console.log("login error",err);
        // toast.error("loading error :"+err.message);
      });
  }, []);

  useEffect(() => {
    if (!spineRef.current) {
      spineRef.current = new SpinePlayer("spine-player-container", {
        jsonUrl: "/spine/loading/fire.json",
        atlasUrl: "/spine/loading/fire.atlas",
        animation: "animation",
        backgroundColor: "#00000000",
        alpha: true,
        premultipliedAlpha: true,
        preserveDrawingBuffer: true,
        showControls: false,
        viewport: {
          debugRender: false,
          x: 0,
          y: 0
        },
        showLoading: false,
        success: (player) => {
          player.play();
        }
      });
    }

    return () => {
      if (spineRef.current) {
        spineRef.current.dispose();
        spineRef.current = null;
      }
    };
  }, []);
  const { isPc } = useIsPc();
  return (
    <PageTransition>
      <div className={`flex flex-col w-full h-screen  items-center  min-h-screen ${isPc ? "pt-[0%]" : "pt-[25%]"}`}>
        <div
          id="spine-player-container"
          style={{
            width: "400px",
            height: "600px",
            marginBottom: "100px",
            position: "relative",
            background: "transparent",
            mixBlendMode: "normal",
          }}
        ></div>
      </div>
    </PageTransition>
  );
}

export default Login;
