import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// 自定义toast样式
const toastStyle = {
  width: "auto",
  minHeight: "40px",
  margin: "0",
  borderRadius: "10px",
  backgroundColor: "rgba(0, 0, 0)",
  zIndex: 9999,
  color: "#fff",
};

export const ToastMessage = ({
  title,
  message,
  type = "success",
}: {
  title: string;
  message: string;
  type?: "success" | "error";
}) => (
  <div className="flex items-start justify-between min-w-[200px]  max-w-[200px]">
    <div className="left flex flex-col gap-1">
      {title && <h4 className="font-semibold text-white/90">{title}</h4>}
      <p className="text-white/50">{message}</p>
    </div>
    <div className="right block items-start ml-4 w-8 h-full">
      {type === "success" ? (
        <IoCheckmarkCircle className="w-6 h-6 text-green-500" />
      ) : (
        <IoCloseCircle className="w-6 h-6 text-red-500" />
      )}
    </div>
  </div>
);

// 自定义关闭按钮组件

export const Toast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        top: 100,
        right: 20,
        width: "auto",
        maxWidth: "400px",
        zIndex: 9999,
        gap: "2px",
      }}
      icon={false}
      closeButton={false}
      toastStyle={toastStyle}
    />
  );
};
