import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface SoundState {
  isSoundOn: boolean
  toggleSound: () => void
}

export const useSoundStore = create<SoundState>()(
  persist(
    (set) => ({
      isSoundOn: true,
      toggleSound: () => set((state) => ({ isSoundOn: !state.isSoundOn })),
    }),
    {
      name: 'sound-storage',
    }
  )
) 