import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ConfigState {
  diffTime  : number
  setDiffTime: (time: number) => void
}

export const useConfigStore = create<ConfigState>()(
  persist(
    (set) => ({
      diffTime: 0,
      setDiffTime: (time) => set({ diffTime: time }),
    }),
    {
      name: 'config-storage',
    }
  )
)