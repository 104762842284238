
import ReactDOM from "react-dom/client";
import App from "./App";
import { Toast } from "./components/common/Toast";
import "./index.css";
import "./utils/i18n";
import { init } from "./utils/init";

// 创建 Toast 容器
const toastRoot = document.createElement("div");
toastRoot.id = "toast-root";
document.body.appendChild(toastRoot);
ReactDOM.createRoot(toastRoot).render(<Toast />);

const root = ReactDOM.createRoot(document.getElementById("root")!);
init(true);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
