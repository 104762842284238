
import gameActive from "./gameActive.png";
import gameUnActive from "./gameUnActive.png";
import invite from "./invite.png";
import inviteActive from "./inviteActive.png";
import rank from "./rank.png";
import rankActive from "./rankActive.png";
import task from "./task.png";
import taskActive from "./taskActive.png";
import wallet from "./wallet.png";
import walletActive from "./walletActive.png";
export const navIcons = {
  task,
  rank,
  wallet,
  invite,
  gameActive,
  gameUnActive,
  taskActive,
  rankActive,
  walletActive,
  inviteActive,
};
