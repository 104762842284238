import bbq from './bbq.png';
import bbqCoins from './bbqCoins.png';
import bbqWar from './bbqWar.png';
import help from './help.png';
import order from './order.png';
import orderPending from './orderPending.png';
import x from './x.png';
export const icons = {
  bbq,
  bbqWar,
  order,
  orderPending,
  bbqCoins,
  help,
  x,
};


