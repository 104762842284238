import { useConfigStore } from "@/store/config";
import { LoginRequest, UserInfo } from "@/types/auth";
import request from "@/utils/axios";

export const authApi = {
  login: (data: LoginRequest) => {
    return request.post("/login/telegram", data);
  },
  
  getUserInfo: () => {
    return request.get<UserInfo>("/user/me");
  },
  getServerTime: () => {
   
    return request.post<number>("/user/game-war/service_time").then((res) => {
      const serverTime = new Date(res.data);
      const currentTime = new Date();
      const diff = serverTime.getTime() - currentTime.getTime();
      useConfigStore.getState().setDiffTime(diff);
      return res.data
    })  ;
  },
  serverTime: () => {
    return request.post<string>("/user/game-war/service_time");
  }
};
